import React, { FC } from 'react';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import { IQuote } from './models';

import './Quote.scss';

const Quote: FC<IQuote> = ({ quoteText, quoteAuthor, authorPosition, img, alt }) => (
  <blockquote className="dt-quote">
    <p className="dt-quote__quote-text">{quoteText}</p>
    <footer className="clearfix">
      <span className="dt-quote__img">
        <UmbracoImage image={img} alt={alt} />
      </span>
      <div className="dt-quote__author-data">
        <span className="dt-quote__author">{quoteAuthor}</span>
        <cite className="dt-quote__position">{authorPosition}</cite>
      </div>
    </footer>
  </blockquote>
);

export default Quote;
